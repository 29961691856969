import { screens } from '@/utils/tailwindcss';
import { useEffect, useState } from 'react';

// media observer for checking if screen size is less than or equal to the given size
export const useIsScreen = (size: keyof typeof screens) => {
  const query = `(max-width: ${screens[size]}px)`;

  const [isScreen, setIsScreen] = useState(() => {
    if (typeof window === 'undefined') return false;
    return window.matchMedia(query).matches;
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const media = window.matchMedia(query);

    setIsScreen(media.matches);
    const handler = ({ matches }: MediaQueryListEvent) => {
      setIsScreen(matches);
    };

    media.addEventListener('change', handler);
    return () => media.removeEventListener('change', handler);
  }, [query]);

  return isScreen;
};
