import { DayOfWeek, OpenFromTo } from '@/graphql/types';

export const DEFAULT_LOCALE: string = 'it-IT';
export const DEFAULT_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'short',
  day: '2-digit',
};

export const BLOG_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const ONE_DAY_SECONDS = 86400;
export const ONE_DAY_IN_MILLISECONDS = ONE_DAY_SECONDS * 1000;

export const formatDate = (date: Date, locale = DEFAULT_LOCALE, localeOptions = DEFAULT_LOCALE_OPTIONS) =>
  date.toLocaleString(locale, localeOptions);

const openhoursConverter = (openhours: OpenFromTo[]) => {
  let str = '';
  for (let i = 0; i < openhours.length; i++) {
    const openhour = openhours[i];
    const isLast = i === openhours.length - 1;
    if (isLast) {
      str += `${openhour.from} / ${openhour.to}`;
      continue;
    }
    str += `${openhour.from} /${openhour.to}` + '-';
  }
  return str;
};

const timeConverter = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const dayofweekConverter = (dayofweek: DayOfWeek) => {
  switch (dayofweek) {
    case DayOfWeek.Monday:
      return 'LUNEDÌ';
    case DayOfWeek.Tuesday:
      return 'MARTEDÌ';
    case DayOfWeek.Wednesday:
      return 'MERCOLEDÌ';
    case DayOfWeek.Thursday:
      return 'GIOVEDÌ';
    case DayOfWeek.Friday:
      return 'VENERDÌ';
    case DayOfWeek.Saturday:
      return 'SABATO';
    case DayOfWeek.Sunday:
      return 'DOMENICA';
  }
};

export const createOpeningHourObj = (dayofweek: DayOfWeek, openhours: OpenFromTo[]) => ({
  dayofweek: dayofweekConverter(dayofweek),
  openhours: openhoursConverter(openhours),
});
