import { PREFIXED_CALLCENTER_TELEPHONE_NUMBER } from '@/costants';
import { trackPhoneNumberClick } from '@/utils/trackingGtm';

const PhoneNumberCTA = ({ anchorClassName = '', className = 'only:' }: { anchorClassName?: string; className?: string }) => (
  <a
    href={`tel:${PREFIXED_CALLCENTER_TELEPHONE_NUMBER}`}
    data-cy="phone-number-cta"
    className={`${className} text-center text-base font-normal text-primary md:text-sm`}
    onClick={() => trackPhoneNumberClick()}
  >
    oppure chiama il{' '}
    <span className={`${anchorClassName} text-base font-semibold text-inherit underline md:text-sm`}>
      {PREFIXED_CALLCENTER_TELEPHONE_NUMBER}
    </span>
  </a>
);

export default PhoneNumberCTA;
