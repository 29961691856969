type SkeletonProps = React.PropsWithChildren & {
  className?: string;
};

export const SkeletonRow = ({ className }: SkeletonProps) => {
  return (
    <div className="flex h-full w-full animate-pulse">
      <div className={`rounded-lg bg-gray-100 dark:bg-gray-200  ${className}`}></div>
    </div>
  );
};
