const { join } = require('path');

import { TailwindPlugin } from '@davincihealthcare/elty-design-system-foundations';
import type { Config } from 'tailwindcss';

export default {
  plugins: [TailwindPlugin],
  content: [
    join(__dirname, '{src,pages,components}/**/*!(*.stories|*.spec).{ts,tsx,html}'),
    './node_modules/@davincihealthcare/**/*.{ts,tsx,html,js}',
  ],
  theme: {
    extend: {
      animation: {
        slideRight: 'slideRight 0.30s ease-in',
        slideLeft: 'slideLeft 0.30s ease-out',
        slideBottom: 'slideBottom 0.30s ease-in',
      },
      boxShadow: {
        card: '46px 167px 49px 0px rgba(15, 50, 80, 0.00), 29px 107px 44px 0px rgba(15, 50, 80, 0.01), 17px 60px 37px 0px rgba(15, 50, 80, 0.02), 7px 27px 28px 0px rgba(15, 50, 80, 0.03), 2px 7px 15px 0px rgba(15, 50, 80, 0.04)',
        bigCard:
          '34px 167px 49px 0px rgba(15, 50, 80, 0.00), 29px 107px 44px 0px rgba(15, 50, 80, 0.01), 17px 60px 37px 0px rgba(15, 50, 80, 0.02), 7px 27px 28px 0px rgba(15, 50, 80, 0.03), 2px 7px 15px 0px rgba(15, 50, 80, 0.04)',
      },
      keyframes: {
        slideRight: {
          '0%': { transform: 'translateX(100%)', display: 'none' },
          '100%': { transform: 'translateX(0px)', display: 'block' },
        },
        slideLeft: {
          '0%': { transform: 'translateX(0px)', display: 'block' },
          '100%': { transform: 'translateX(100%)', display: 'none' },
        },
        slideBottom: {
          '0%': { transform: 'translateY(100%)', display: 'none' },
          '100%': { transform: 'translateY(0px)', display: 'block' },
        },
      },
      borderRadius: {
        '6xl': '3rem',
      },
      colors: {
        sisalute: {
          green: '#7AB70F',
          blue: '#003662',
          gray: '#5C5C5C',
          lightGray: '#A1A1A1',
          white: '#F8F8F8',
        },
        zaffiro: {
          50: '#E9EFFD',
          100: '#D3E0FB',
          200: '#A8C1F7',
          300: '#7CA1F3',
          400: '#5182EF',
          500: '#2563EB',
          600: '#1E4FBC',
          700: '#163B8D',
          800: '#0F285E',
          900: '#07142F',
        },
        elty: {
          'gray-blue': '#5081A0',
          green: '#0BBCA7',
          blue: '#0F3250',
        },
      },
      fontFamily: {
        sans: ['var(--font--plusjakartasans)', 'Sans-serif'],
        'sisalute-apex': ['var(--font-apex)', 'Sans-serif'],
        'sisalute-cabin': ['var(--font-cabin)', 'Sans-serif'],
      },
      maxHeight: {
        '60-screen': '60vh',
      },
    },
  },
} satisfies Config;
