import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@/../tailwind.config';
const fullConfig = resolveConfig(tailwindConfig);

export const screens = {
  xl: parseFloat((fullConfig?.theme?.screens as { [k: string]: string })?.xl.replace('px', '')),
  lg: parseFloat((fullConfig?.theme?.screens as { [k: string]: string })?.lg.replace('px', '')),
  md: parseFloat((fullConfig?.theme?.screens as { [k: string]: string })?.md.replace('px', '')),
  sm: parseFloat((fullConfig?.theme?.screens as { [k: string]: string })?.sm.replace('px', '')),
};
